import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import { legal, container, wrapper, column, list, link } from './cookies-template.module.scss';

import { IPage } from '../../models/page.model';

import { IQueryAllResult, usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';
import { getNodes } from '../../utils/get-nodes';

interface ILogoQueryResult {
    allHomePage: IQueryAllResult<Pick<IPage, 'locale' | 'pathname'>>;
}

const CookiesTemplate = () => {
    const { locale } = usePageContext();
    const { allHomePage } = useStaticQuery<ILogoQueryResult>(query);
    const localizedHomePathname =
        getNodes(allHomePage).find((page) => page.locale === locale)?.pathname || '/';

    return (
        <div className={container}>
            <div className={wrapper}>
                <h2>L’Oréal Polska Sp. z o.o.</h2>
                <h1>TABELA PLIKÓW COOKIES</h1>
                <div className={column}>
                    <h3>CZYM SĄ PLIKI COOKIES?</h3>
                    <p>
                        Pliki Cookies to niewielkie pliki tekstowe przechowywane na Twoim urządzeniu
                        (komputerze, tablecie lub telefonie komórkowym), kiedy jesteś połączony z
                        Internetem co obejmuje strony internetowe Grupy L’Oréal. Pliki Cookies mogą
                        zawierać ustawienia witryn lub być używane do śledzenia interakcji
                        użytkowników z witryną. W celu uzyskania dalszych informacji prosimy o
                        zapoznanie się z następującą stroną:{' '}
                        <a href="http://www.aboutcookies.org" target={'_blank'} rel="noreferrer">
                            http://www.aboutcookies.org
                        </a>
                        .
                    </p>
                </div>
                <div className={column}>
                    <h3>DEZAKTYWACJA PLIKÓW COOKIES</h3>
                    <p>
                        Możesz łatwo dostosować ustawienia Twojej przeglądarki w odniesieniu do
                        aktywacji oraz dezaktywacji plików Cookies. Prosimy o zapoznanie się opcją
                        pomocy Twojej wyszukiwarki w razie potrzeby.
                    </p>
                </div>
                <div className={column}>
                    <h3>RODZAJE I ROLA PLIKÓW COOKIES</h3>
                    <p>
                        Wiele plików Cookies jest wykorzystywanych w celu zwiększenia użyteczności
                        oraz polepszenia funkcjonalności stron internetowych/aplikacji. Z tego
                        względu wyłączenie plików Cookies może uniemożliwić Ci korzystanie z
                        niektórych części naszych stron internetowych/aplikacji, tak jak to wskazano
                        w tabeli dotyczącej plików Cookies.
                    </p>
                </div>
                <div id="ot-sdk-cookie-policy" className={list}></div>
                <div className={legal}>
                    <span>
                        Firma L’Oréal Polska Sp. z o.o. jest odpowiedzialna za dane osobowe, którymi
                        się z nami dzielisz. Kiedy mówimy „L’Oréal”, „nam”, „nasze”, „my”, właśnie
                        do tego podmiotu się odnosimy. L’Oréal jest „administratorem” w rozumieniu
                        właściwego prawa ochrony danych.
                    </span>
                    <span>L’Oréal Polska Sp. z o.o.</span>
                    <span>Ul. Grzybowska 62</span>
                    <span>00-844 Warszawa</span>
                    <Link to={localizedHomePathname} className={link}>
                        www.beautycolab.pl
                    </Link>
                    <span>
                        Spółka powołała Inspektora Ochrony Danych dostępnego pod adresem
                        personal-da@loreal.com.
                    </span>
                </div>
            </div>
        </div>
    );
};

const query = graphql`
    query {
        allHomePage: allPage(filter: { type: { eq: "home" } }) {
            edges {
                node {
                    pathname
                    locale
                }
            }
        }
    }
`;

export default CookiesTemplate;
